import React from 'react'
import classNames from 'classnames'
import { useMedia } from 'react-use'
import { NavLink } from 'react-router-i18n'
import moment from 'moment'

import Header from 'layouts/Header'
import Layout from 'layouts/Layout'
import { Overflow } from 'layouts/Game'
import Button from 'components/Button'
import Loader from 'components/Loader'
import I18n from 'lang'
import gamesApi from 'api'
import useHomeBlockStore from 'store/home-block'
import useSiteStore from 'store/site'


const Card = props => {
  const {
    game: gameProps,
    size,
  } = props

  const horizontal = size === 'big'
  const className = classNames(
    {'col-span-2 p-2': size === 'big'},
  )

  const params = new URLSearchParams(gameProps?.api?.attrs)
  const apiUrl = `/${gameProps.api?.basepath}/get/last?${params.toString()}`.replace(/\/+/g, '/')
  const showAuthor = gameProps.showAuthor

  const title = gameProps.title
  const iconUrl = gameProps.icon?.big
  const externalLink = gameProps.url.indexOf('http') === 0
  const to = externalLink ? gameProps.url : [gameProps.locale, gameProps.url].join('/').replace(/\/+/g, '/')
  const locale = gameProps.locale
  const isNew = gameProps.new

  const [loading, setLoading] = React.useState(false)
  const [game, setGame] = React.useState(null)
  const [error, setError] = React.useState(false)

  const isMobile = useMedia('(max-width: 625px)')

  React.useEffect(() => {

    const fetchAllGames = async () => {
      setLoading(true)

      try {
        const _game = await gamesApi.get(apiUrl)
        setGame(_game.data)

      } catch (error) {
        setError(true)

      }

      setLoading(false)
    }

    ! externalLink && fetchAllGames()

  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const icon = (
    <div>
      <img
        src={iconUrl}
        alt={title}
        className={classNames(
          'flex-grow flex-1 object-cover',
          { 'order-2': horizontal },
          { 'p-5': horizontal },
        )}
        style={{
          ...(
            horizontal ? (
              isMobile ?
                { height: '180px', width: '180px' } :
                { width: '362px', height: '362px' }
            ) : (
              isMobile ?
                { height: '94px', width: 'auto' } :
                { height: '160px', width: 'auto' }
            )
          ),
        }}
      />
    </div>
  )

  const Component = externalLink ? 'a' : NavLink

  return (
    <div className={classNames(
      className,
      'bg-white rounded-lg shadow-lg relative overflow-hidden',
      {'py-4 lg:py-10': ! horizontal},
      {'py-4 lg:p-0': horizontal},
    )}>
      <Overflow active={loading || error} className='flex items-center justify-center'>
        <Loader active={loading} />
        {
          error &&
          'Juego no disponible.'
        }
      </Overflow>

      <div className={classNames(
        'flex flex-row items-center justify-center h-full',
        {'flex-col': ! horizontal },
      )}>
        {
          ! horizontal &&
          icon
        }
        <div className='flex flex-col flex-grow flex-1 text-center'>
          <h3 className={classNames(
            {'pt-2 lg:pt-8': ! horizontal },
            'text-black font-semibold text-[22px] lg:text-2xl sm:text-3xl font-sans-pro ml-3 mr-3',
          )}>{title}</h3>
          {
            (game?.autor && showAuthor) &&
            <p className='capitalize font-sans-pro text-sm text-gray-500 font-semibold'>
              {I18n.getTranslation({ pathname: '/' + locale, }, 'by')} {game.autor}
            </p>
          }
          {
            game?.publicado &&
            <p className='capitalize font-sans-pro text-sm text-gray-500'>
              {moment(game?.publicado).locale(locale).format('dddd, DD.MM.YY')}
            </p>
          }
          <div className='flex-grow items-end flex justify-center'>
            <Button
              as={Component}
              to={to}
              buttonProps={{ignoreLocale: true, href: to}}
              className='uppercase mt-5 text-sm font-semibold font-sans-pro'
            >
              {I18n.getTranslation({ pathname: '/' + locale, }, 'play')}
            </Button>
          </div>
        </div>
        {
          horizontal &&
          icon
        }
      </div>

        {
          isNew &&
          <span className={classNames(
            'absolute right-0 font-sans-pro text-sm bg-red-500 text-white py-1 px-5 uppercase',
            {'top-12 lg:top-34 sm:top-20': ! horizontal},
            {'top-1/2 transform -translate-y-1/2': horizontal},
          )}>
          <I18n t='game.new' />
        </span>
      }
    </div>
  )
}


Card.defaultProps = {
  locale: 'es',
}


export default function Home() {
  const homeBlockStore = useHomeBlockStore()
  const siteStore = useSiteStore()

  return (
      <Layout>
        <Header showGeneralTitle showMenu />
        {
          homeBlockStore.items?.map((block, index) => (
            <div
              className='p-3 lg:p-4 bg-disabled-lightest'
              style={{
                background: block.bgColor,
              }}
              key={index}
            >
              {
                block.title && (
                  <div className='font-bold text-xl pb-3 lg:pb-5 text-center font-sans-pro text-gray-600 uppercase'>
                    {block.title}
                  </div>
                )
              }
              <div
                className={classNames(
                  'gap-3 lg:gap-4',
                  'grid grid-cols-2 lg:grid-cols-4 grid-flow-row ',
                )}
              >
                {
                  block.items?.map(
                    (item, index) => <Card key={item.game.id} {...item} />
                  )
                }
            </div>
          </div>
        ))
      }

      <div className='bg-disabled-lightest p-3 lg:p-4'>
        <div className='text-sm text-right' dangerouslySetInnerHTML={{__html: siteStore?.site?.home?.footer}} />
      </div>
    </Layout>
  )
}
