import React, { useEffect } from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { useLocation } from 'react-use'
import moment from 'moment'

import Home from 'scenes/Home'
import Sudoku from 'scenes/Sudoku'
import Crossword from 'scenes/Crossword'
import LetrasConectadas from 'scenes/LetrasConectadas'
import CurrentChallenge from 'scenes/CurrentChallenge'
import TilesApp from 'scenes/Tiles/components/TilesApp'
import DifferencesApp from 'scenes/Differences/components/DifferencesApp'
import LexiretoApp from 'scenes/Lexireto/components/LexiretoApp'
import Words from 'scenes/Words'
import Loader from 'components/Loader'
import { gamesBasePathname } from 'config'
import useSiteStore from 'store/site'
import useGameInfoStore from 'store/game'
import useHomeBlockStore from 'store/home-block'
import useNavStore from 'store/nav'
import { trigger } from 'lib/externalApi'


const PageTracking = () => {
  const location = useLocation()
  const [initialized, setInitialized] = React.useState(false)

  React.useEffect(() => {
    if (!window.location.href.includes('localhost')) {
      // React Google Analytics Init
    }
    setInitialized(true)
  }, [])

  React.useEffect(() => {
    moment.updateLocale('es')

    window.scrollTo(0, 0)

    const urlParams = new URLSearchParams(location.search)
    const userId = urlParams.get('userid')
    if (userId) {
      localStorage.setItem('userId', userId)
    }

    trigger(
      'pageview',
      {
        userId: localStorage.getItem('userId'),
        pathname: location.pathname,
      }
    )

    if (process.env.REACT_APP_TRACKING !== 'false') {
      const pathname = location.pathname
      if (
        initialized &&
        pathname.replace(/\/(es|ca|en)?\/?/i, '').length > 0
      ) {
        window?.didomiOnReady?.push(function () {
          window?._comscore.push({
            c1: "2",
            c2: "6398301",
            cs_ucfr: window?.Didomi.getUserConsentStatusForVendor('c:comscore-jUFc9icY') === false ? "0" : "1"
          })
        })
        window?.dataLayer?.push({
          event: 'virtualPageView',
          pageName: pathname,
          sections: '/juegos#'
        })
      }

      let ruta
      if (pathname === '/juegos/' || pathname === '/juegos') {
        ruta = 'juegos'
      } else {
        ruta = pathname.replace(/\/(juegos\/es\/||juegos\/ca\/|juegos\/en\/)?\/?/i, '')
      }

      let type = pathname.replace(/\/(juegos\/es\/||juegos\/ca\/|juegos\/en\/)?\/?/i, '')
      let id = 0

      if (ruta === 'juegos') {
        type = 'home'
      } else {
        const ruta_ = ruta.split('/')
        type = ruta_[0]

        if (ruta_[0] !== 'sudoku' && ruta_[1] !== undefined && ruta_[1] !== '') {
          id = ruta_[1]

        } else if (ruta_[0] === 'sudoku') {
          if (ruta_[2] !== undefined && ruta_[2] !== '') {
            id = ruta_[2]

          }
        }
      }

      const finalPathname = pathname.replace('/' + id, '');
      document.dispatchEvent(new CustomEvent('gamesSectionChangeUrl', {
        detail: {
          path: finalPathname,
          type: type,
          id: id
        }
      }));

    }

  }, [initialized, location])

  return null
}

function App() {
  const base = '/:locale(es|ca|en)'

  const [loading, setLoading] = React.useState(true)

  const siteStore = useSiteStore()
  const gameStore = useGameInfoStore()
  const homeBlockStore = useHomeBlockStore()
  const navStore = useNavStore()

  useEffect(() => {
    const getAll = async () => {
      setLoading(true)

      await siteStore.getSite()
      await gameStore.getGames()
      await homeBlockStore.getHomeBlocks()
      await navStore.getNavigation()

      setLoading(false)
    }
    getAll()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return <Loader center active />
  }

  return (
    <div className='max-w-7xl mx-auto text-base'>
      <Router basename={gamesBasePathname}>
        <Switch>
          {
            gameStore.items.map(
              game => {
                let routes = []

                if (game.id.startsWith('sudoku-')) {
                  routes = [
                    ...routes,
                    <Route exact path={`${base}${game.url}`} render={
                        props => <Sudoku cols={3} gameProps={game} {...props} />
                    } />,
                    <Route exact path={`${base}${game.url}/:id`} render={
                      props => <Sudoku cols={3} gameProps={game} {...props} />
                    } />
                  ]
                }

                if (game.id === 'mini-sudoku') {
                  routes = [
                    ...routes,
                    <Route exact path={`${base}/mini-sudoku`} render={
                      props => <Sudoku cols={2} gameProps={game} {...props} />
                    } />,
                    <Route exact path={`${base}/mini-sudoku/:id`} render={
                      props => <Sudoku cols={2} gameProps={game} {...props} />
                    } />,
                  ]
                }

                if (game.id === 'crucigrama') {
                  routes = [
                    ...routes,
                    <Route exact path={`${base}/crucigrama`} render={
                      props => (
                        <Crossword gameProps={game} {...props} />
                      )
                    } />,
                    <Route exact path={`${base}/crucigrama/:id`} render={
                      props => (
                        <Crossword gameProps={game} {...props} />
                      )
                    } />,
                  ]
                }

                if (game.id === 'cruciveloz') {
                  routes = [
                    ...routes,
                    <Route exact path={`${base}/cruciveloz`} render={
                      props => (
                        <Crossword gameProps={game} {...props} type='minicrossword' />
                      )
                    } />,
                    <Route exact path={`${base}/cruciveloz/:id`} render={
                      props => (
                        <Crossword gameProps={game} {...props} type='minicrossword' />
                      )
                    } />,
                  ]
                }

                if (game.id === 'fastcrossword') {
                  routes = [
                    ...routes,
                    <Route exact path={`${base}/fastcrossword`} render={
                      props => (
                        <Crossword gameProps={game} {...props} type='minicrossword' />
                      )
                    } />,
                    <Route exact path={`${base}/fastcrossword/:id`} render={
                      props => (
                        <Crossword gameProps={game} {...props} type='minicrossword' />
                      )
                    } />,
                  ]
                }

                if (game.id === 'reto-actualidad') {
                  routes = [
                    ...routes,
                    <Route exact path={`${base}/reto-actualidad`} render={
                      props => <CurrentChallenge gameProps={game} {...props} />
                    } />,
                    <Route exact path={`${base}/reto-actualidad/:id`} render={
                      props => <CurrentChallenge gameProps={game} {...props} />
                    } />,
                  ]
                }

                if (game.id.startsWith('quiz-')) {
                  routes = [
                    ...routes,
                    <Route exact path={`${base}${game.url}`} render={
                      props => <CurrentChallenge gameProps={game} {...props} type='quiz' />
                    }/>,
                    <Route exact path={`${base}${game.url}/:id`} render={
                      props => <CurrentChallenge gameProps={game} {...props} type='quiz' />
                    }/>,
                  ]
                }

                if (game.id === 'mots-encreuats') {
                  routes = [
                    ...routes,
                    <Route exact path={`${base}/mots-encreuats`} render={
                      props => <Crossword gameProps={game} type='motsencreuats' {...props} />
                    } />,
                    <Route exact path={`${base}/mots-encreuats/:id`} render={
                      props => <Crossword gameProps={game} type='motsencreuats' {...props} />
                    } />,
                  ]
                }

                if (game.id === 'letras') {
                  routes = [
                    ...routes,
                    <Route exact path={`${base}/letras`} render={
                      props => <LetrasConectadas gameProps={game} {...props} />
                    } />,
                    <Route exact path={`${base}/letras/:id`} render={
                      props => <LetrasConectadas gameProps={game} {...props} />
                    } />,
                  ]
                }

                if (game.id === 'baldosas') {
                  routes = [
                    ...routes,
                    <Route exact path={`${base}/baldosas`} render={
                      props => <TilesApp gameProps={game} {...props} />
                    } />,
                    <Route exact path={`${base}/baldosas/:id`} render={
                      props => <TilesApp gameProps={game} {...props} />
                    } />,
                  ]
                }

                if (game.id === 'diferencias') {
                  routes = [
                    ...routes,
                    <Route exact path={`${base}/diferencias`} render={
                      props => <DifferencesApp gameProps={game} {...props} />
                    } />,
                    <Route exact path={`${base}/diferencias/:id`} render={
                      props => <DifferencesApp gameProps={game} {...props} />
                    } />,
                  ]
                }

                if (game.id === 'lexireto') {
                  routes = [
                    ...routes,
                    <Route exact path={`${base}/lexireto`} render={
                      props => <LexiretoApp gameProps={game} {...props} />
                    } />,
                    <Route exact path={`${base}/lexireto/:id`} render={
                      props => <LexiretoApp gameProps={game} {...props} />
                    } />,
                  ]
                }

                if (game.id === 'wordled') {
                  routes = [
                    ...routes,
                    <Route exact path={`${base}/wordled`} render={
                      props => <Words gameProps={game} {...props} />
                    } />,
                    <Route exact path={`${base}/wordled/:id`} render={
                      props => <Words gameProps={game} {...props} />
                    } />,
                  ]
                }

                return routes
              }
            )
          }

          <Route path={`${base}`} component={Home} />
          <Route path='/' component={Home} />
        </Switch>
        <PageTracking />
      </Router>
    </div>
  )
}

export default App
