const LetrasConectadasCA =
    {
        title: "Letras",
        actions: {
            checkGame: "Comprovar lletres",
            resolveGame: "Resoldre lletres",
            restartGame: "Reiniciar lletres",
            apply: "Aplicar",
            clean: "Esborrar"
        },
        messages: {
            saved: {
                message: ({date}) => <>El joc s'ha guardat correctament. El podràs acabar fins
                    el <strong>{date}</strong></>
            },
            letters: "Lletres",
            howToPlayTitle: "Com es juga",
            howToPlayText1:
                "Escriu totes les paraules que puguis formar amb les lleters del quadre, utilitzant només les contígües en totes les direccions. Fixa't en els exemples.",
            howToPlayText2:
                "Cada lletra la pots utilitzar tantes vegades com vulguis, però no en una mateixa paraula.",
            howToPlayTextFull:
                "Escriu totes les paraules que puguis formar amb les lleters del quadre, utilitzant només les contígües en totes les direccions. Fixa't en els exemples. Atenció! Cada lletra la pots utilitzar tantes vegades com vulguis, però no en una mateixa paraula.",
            correctword: "Paraula correcta!",
            wrongword: "Paraula incorrecta!",
            repeatedword: "Paraula repetida!"
        }
    }

export default LetrasConectadasCA