import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

export const gamesApiBase = (
  process.env.REACT_APP_API_BASE_URL ||
  'https://gamesapi.arambee.com/public/api/'
)

// Create a instance of axios to use the same base url.
const gamesApi = axios.create({
  baseURL: gamesApiBase,
  mode: 'cors',
  include: 'coolie'
})

export const getUserId = () => {
  let userId = (
    window?.gg?.accounts?.getUser()?.guid
  )

  if (!userId) {
    const search = new URLSearchParams(window.location.search)
    userId = search.get('userId')
  }

  if (userId) {
    localStorage.setItem('userId', userId)
  } else {
    userId = localStorage.getItem('userId');
  }

  if (!userId) {
    userId = uuidv4() + '-ONLINE'
    localStorage.setItem('userId', userId)
  }

  return userId
}

gamesApi.defaults.params = {};
gamesApi.interceptors.request.use(
  config => {
    config.url = config.url.replace(/\/+/g, '/')

    const userId = getUserId()

    if (userId) {
      if (config.method === 'get') {
        config.params.userid = userId

      } else {
        if (config?.data?.userid) {
          config.data = { ...config.data, userid: config.data.userid }
        } else {
          config.data = { ...config.data, userid: userId }
        }

      }
    }
    return config
  },
)

export default gamesApi

