
const TilesGameLocaleES = {
    title: "Baldosas",
    totalPairs: "Total parejas",
    resolvedPairs: "Mis parejas",
    marker: "Marcador",
    resolveGame: "Resolver",
    restartGame: "Reiniciar",
    howToPlay: "Cómo se juega",
    howToPlayText: "Empareja las baldosas idénticas hasta completar el panel antes de que se agote el tiempo. ¡Dispones de 4 minutos! Fíjate bien, algunas baldosas pueden estar giradas.",
    howToPlayTextFull:
        () =>
            <>
                <strong>Cómo se juega</strong>
                <br />
                Empareja las baldosas idénticas hasta completar el panel en el menor tiempo posible. Fíjate bien, algunas baldosas pueden estar giradas.
            </>,
    incorrectlyPaired: "¡Pareja incorrecta!",
    successfullyPaired: "¡Pareja correcta!",
    messages: {
      init: 'Tienes 4 minutos para terminar',
      '3m': 'Te quedan 3 minutos',
      '2m': 'Te quedan 2 minutos',
      '1m': 'Te queda 1 minuto',
      '30s': 'Te quedan 30 segundos',
      '10s': 'Te quedan 10 segundos',
    },
    incomplete: {
      title: '¡LÁSTIMA!',
      message: 'Has agotado el tiempo',
    }
}

export default TilesGameLocaleES

