import classNames from 'classnames'

import './Grid.scss'


export default function Grid(props) {
  const {
    board,
    onClickItem,
  } = props

  return (
    <div
      id='grid-container'
      className={classNames(
        `sudoku_${props.className}`,
        'w-full',
      )}
    >
      <table
        border='0'
        cellSpacing='0'
        cellPadding='0'
        className='border-2 border-black'
      >
        <tbody>
          {
            board.map(
              (items, rowIndex) => (
                <tr key={`tr_${rowIndex}`} className='border border-disabled'>
                  {
                    items.map(
                      (item, colIndex) => (
                        <td
                          key={`tr_${colIndex}`}
                          onClick={() => item.clickable && onClickItem(rowIndex, colIndex)}
                          className='group w-12 h-12 sm:w-16 sm:h-16 m-0 p-0 cursor-pointer relative'
                        >
                          {
                            item.value !== '' && item.numberError === true &&
                            <div className='absolute left-0 top-0 w-full h-full flex items-center justify-center'>
                              <div className='origin-center w-full border-danger border-b-2 absolute transform rotate-45' />
                              <div className='origin-center h-full border-danger border-l-2 absolute transform rotate-45' />
                            </div>
                          }
                          <div
                            className={classNames(
                              'border-l border-disabled',
                              'left-0 top-0 w-full h-full',
                              'text-4xl',
                              'font-semibold',
                              'transition-all',
                              'outline-none',
                              'flex items-center justify-center',
                              {
                                'bg-white cursor-pointer': item.clickable && !item.active,
                                'group-hover:bg-primary-light hover:z-10': (
                                  item.clickable &&
                                  !item.active
                                ),
                                'bg-disabled-lightest text-disabled-light cursor-default': !item.clickable,
                                'bg-primary text-white z-20': item.active,
                                'text-green-600': item.numberError === false,
                                'text-danger': item.numberError === true,
                              },
                            )}
                          >{ item.value }</div>
                          {
                            item.error &&
                            <div
                              className='
                                w-1 h-1
                                absolute
                                right-1 bottom-1
                                rounded-full
                                bg-danger-light
                                lg:w-3 lg:h-3
                              '
                            />
                          }
                        </td>
                      )
                    )
                  }
                </tr>
              )
            )
          }
        </tbody>
      </table>
    </div>
  )
}
