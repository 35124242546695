export default function TilesHexagonAnchor(props) {
    let opacity = 0
    if(props.selected) {
        opacity = 0.3
    }

    return (
        <svg
            x={props.x + '%'}
            y={props.y + '%'}
        >
            <polygon
                points="196.815,44.929 85.424,44.923 29.725,141.398 85.418,237.86 196.817,237.862 252.508,141.391"
                style={{cursor: props.paired ? 'default' : 'pointer'}}
                onClick={() => { props.onClick(props.idx)}}
                // fill="#00FFFF"
                opacity={opacity}
            />
        </svg>
    )
}