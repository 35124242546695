import './DifferencesModalSuccess.css'
import './DifferencesBoard.css'
import I18n from "../../../lang";

export default function DifferencesModalSuccess(props) {
    const onAnimationEnd = () => {
        props.showSuccessModal(false, false)
    };

    let classes = 'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 diff-modal-success diff-fade-out'

    return (
        <div
            className={classes}
            onAnimationEnd={onAnimationEnd}
        >
            <I18n t='game.differencesGame.successfullyHit' />
        </div>
    )
}