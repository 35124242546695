import I18n from 'lang';
import React from 'react';

export default function LexiretoBoard(props) {
  return (
    <div className='flex-grow-0 pb-4 mx-auto'>
      <div className='bg-primary-light px-3 py-2 flex justify-center items-center space-x-3 relative'>
        {props.alert}
        <div className='text-center text-[#2D7BB9] text-3xl h-10'>
          <div className='flex-grow text-center uppercase'>
            {props.paraula}
          </div>
        </div>
      </div>
      <div className='p-4 lexireto-container m-auto'>
        <ul id='hexGrid'>
          <li className='hex'>
            <div className='hexIn'>
              <div onPointerDown={props.onClickLetter} className='hexLink'>
                {props._currentGameData.letters[0]}
              </div>
            </div>
          </li>
          <li className='hex'>
            <div className='hexIn'>
              <div onPointerDown={props.onClickLetter}className='hexLink'>
                {props._currentGameData.letters[1]}
              </div>
            </div>
          </li>
          <li className='hex'>
            <div className='hexIn'>
              <div onPointerDown={props.onClickLetter} className='hexLink'>
                {props._currentGameData.letters[2]}
              </div>
            </div>
          </li>
          <li className='hex'>
            <div className='hexIn'>
              <div onPointerDown={props.onClickLetter} className='hexLink' id='center-letter'>
                {props._currentGameData.letracentral}
              </div>
            </div>
          </li>
          <li className='hex'>
            <div className='hexIn'>
              <div onPointerDown={props.onClickLetter} className='hexLink'>
                {props._currentGameData.letters[4]}
              </div>
            </div>
          </li>
          <li className='hex'>
            <div className='hexIn'>
              <div onPointerDown={props.onClickLetter} className='hexLink'>
                {props._currentGameData.letters[5]}
              </div>
            </div>
          </li>
          <li className='hex'>
            <div className='hexIn'>
              <div onPointerDown={props.onClickLetter} className='hexLink'>
                {props._currentGameData.letters[6]}
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div className=' lr-container-buttons'>
        <button
          className='lc-button lc-button-reset'
          type='button'
          onClick={(e) => { props.onClickBorrar(e)}}
        >
          <I18n t='game.LexiRetoGame.actions.clean' />
        </button>
        <button
          type='button'
          onClick={() => { props.onClickReorder()}}
          className='inline-flex items-center justify-center align-top ml-4 mr-4 p-3    rounded-full  transition-colors duration-150  lc-button'
        >
          <svg xmlns='http://www.w3.org/2000/svg' width='18.841' height='17' viewBox='0 0 18.841 21.533'><g transform='translate(-1.999 -0.284)'><path fill='#555'd='M11.42,3.57A7.85,7.85,0,1,1,4.283,8.145.785.785,0,0,0,2.858,7.49,9.42,9.42,0,1,0,11.42,2Z' transform='translate(0 0.978)'/><path fill='#555'd='M9.63,6.849V.676a.392.392,0,0,0-.644-.3L5.281,3.462a.392.392,0,0,0,0,.6L8.986,7.151a.392.392,0,0,0,.644-.3Z' transform='translate(1.79)'/></g></svg>
        </button>
        <button
          className='lc-button lc-button-apply'
          type='button'
          onClick={(e) => { props.onClickAply(e)}}
        >
          <I18n t='game.LexiRetoGame.actions.apply' />
        </button>
      </div>
    </div>
  )
}
