import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
// import LogRocket from 'logrocket'
import TagManager from 'react-gtm-module'

import App from 'routes'
import reportWebVitals from 'reportWebVitals'
import 'lang'
import { trigger } from 'lib/externalApi'

import 'moment/locale/ca'
import 'moment/locale/es'
import 'slick-carousel/slick/slick.scss'
import 'slick-carousel/slick/slick-theme.scss'
import './index.scss'

trigger('init')

if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING !== "") {
  if (process.env.NODE_ENV === 'production') {
    // LogRocket.init('quality-games/la-vanguardia')
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING
    })
  }
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('rootGames'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
