export class GamesApi {
  get events() {
    return [
      'game:playing',
      'game:paused',
      'game:finished',
      'game:restarted',
      'game:resolved',
      'game:loaded',
      'pageview',
      'init',
    ]
  }
  static events

  constructor() {
    this.listeners = {}
  }

  on(event, callback) {
    if ( ! this.events.includes(event)) {
      throw new Error(`Invalid event: ${event}. Valid events are: ${this.events.join(', ')}`)
    }

    if (!this.listeners[event]) {
      this.listeners[event] = []
    }

    this.listeners[event].push(callback)
  }

  trigger(event, data) {
    this.listeners[event]?.forEach(callback => callback(data))
  }
}

const gamesApi = new GamesApi()

window.gamesApi = gamesApi

export default gamesApi

export const trigger = (event, data=null) => {
  window?.gamesApi?.trigger(event, data)
}
