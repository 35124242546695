import create from 'zustand'

import gamesApi from 'api'


const useSiteStore = create((set, get) => ({
  site: {},
  getSite: async () => {

    const response = (await gamesApi.get('/config/site')).data

    set({
      site: response,
    })
  },
}))

export default useSiteStore
