import './DifferencesModalError.css'
import './DifferencesBoard.css'

export default function DifferencesModalError(props) {
    const onAnimationEnd = () => {
        props.showSuccessModal(false)
    };

    let classes = 'flex absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 diff-modal-error diff-fade-out '

    return (
        <div
            className={classes}
            onAnimationEnd={onAnimationEnd}
        >
            {props.text}
        </div>
    )
}