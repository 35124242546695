import React from 'react'
import moment from 'moment'
import classNames from 'classnames'
import Slider from 'react-slick'
import { useParams } from 'react-router'
import { NavLink } from 'react-router-i18n'
import { useWindowSize, useMedia } from 'react-use'
import propTypes from 'prop-types'

import gamesApi from 'api'
import I18n from 'lang'
import { ReactComponent as ArrowRightCircleIcon } from 'assets/arrow-right-circle.svg'
import { ReactComponent as ArrowLeftCircleIcon } from 'assets/arrow-left-circle.svg'

import './index.scss'


const NextArrow = props => {
  const {style, className, onClick} = props
  return <div
    onClick={onClick}
    className={classNames(
      className,
      'text-disabled hover:text-primary transition-all',
    )}
    style={style}
  ><ArrowRightCircleIcon className='w-full' /></div>
}
const PrevArrow = props => {
  const {style, className, onClick} = props
  return <div
    onClick={onClick}
    className={classNames(
      className,
      'text-disabled hover:text-primary transition-all',
    )}
    style={style}
  ><ArrowLeftCircleIcon className='w-full' /></div>
}


/**
 *
 * @typedef {Object} HistoricalGames
 * @param {Object} props
 * @param {string} url
 * @param {string} to
 * @param {number} activeId
 * @param {bool} active
 * @param {element} icon
 *
 * @returns {React.Component}
 */
export default function HistoricalGames(props) {
  const { locale='es' } = useParams()
  const [historicalGames, setHistoricalGames] = React.useState([])
  const { url, to, activeId, active, icon } = props

  // const autoScrollRef = React.useCallback(
  //   node => {
  //     node?.scrollTo(node.scrollWidth, 0)
  //   },
  //   [],
  // )

  const { width } = useWindowSize()
  const isMobile = useMedia('(max-width: 768px)')

  // const slider = React.useCallback(
  //   node => {
  //     if (node && 'slickGoTo' in node) {
  //       node.slickGoTo(historicalGames.length - 5)
  //     }
  //   },
  //   [historicalGames],
  // )

  React.useEffect(() => {
    const fetchHistoricalGames = async () => {

      const response = await gamesApi.get(url)
      const data = response.data

      setHistoricalGames(
        data.map(
          item => ({
            id: item.id,
            date: moment(item.publicado).locale(locale).format('ddd DD.MM.YY'),
            statusLabel: item.estadouser?.texto,
            status: item.estadouser?.id,
          })
        )
      )
    }

    fetchHistoricalGames()

  }, [url, active]) // eslint-disable-line

  let amountOfSlides = Math.min(parseInt(width / 240), 5)

  const settingsSlider = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: amountOfSlides,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const games = (
    historicalGames.map(
      (item, index) => (
        <div key={`historicalgames_${item.id}_${index}`}>
          <div className='item'>
            <div className='divider' />
            <NavLink
              className={classNames(
                'pr-5 pl-5 py-5',
                'flex space-x-5',
                'cursor-pointer',
                'group',
                'transition-all',
                { 'active text-primary': activeId === item.id },
              )}
              to={`/${to}/${item.id}`.replace(/\/+/g, '/')}
            >

              <img src={icon} alt='icon' className='caption object-contain'/>

              <div
                className={classNames(
                  'flex flex-col flex-nowrap text-sm leading-4',
                  'group-hover:underline',
                  { 'text-primary': activeId === item.id },
                )}
              >
                <h3 className='font-semibold text-lg -mt-1'>
                  {
                    (
                      ![2, 3].includes(item.status) &&
                      activeId === item.id
                    ) ?
                    <I18n t='game.historicalGames.playing' /> :
                    item.statusLabel
                  }
                </h3>
                {
                  item?.time &&
                  <span>{item.time}</span>
                }
                <span className='capitalize'>{item.date}</span>
              </div>
            </NavLink>
          </div>
        </div>
      )
    )
  )

  return (
    <div className={classNames(
      'w-full',
      'overflow-hidden bg-white',
      'historical-games',
    )} >
      {
        isMobile ?
        <div className='flex whitespace-nowrap overflow-x-auto slick-slide-mobile'>
          { games }
        </div> :
        <div className='px-12'>
          <Slider {...settingsSlider}>
            { games }
          </Slider>
        </div>
      }
    </div>
  )
}


HistoricalGames.propTypes = {
  url: propTypes.string.isRequired,
  to: propTypes.string.isRequired,
  activeId: propTypes.number,
  active: propTypes.bool,
  icon: propTypes.element.isRequired,
}
