export const getStatuses = (currentWord, solution) => {
  if (!currentWord) {
    return []
  }

  const statuses = []
  for (let i = 0; i < solution.length; i++) {
    if (solution[i] === currentWord[i]) {
      statuses.push('exact')
      continue
    }
    if (solution.includes(currentWord[i])) {
      statuses.push('included')
      continue
    }
    statuses.push('wrong')
  }

  return statuses
}
