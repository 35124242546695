import classNames from 'classnames'
import Loader from 'components/Loader'


export default function Layout(props) {
  const { isLoading, className } = props

  return (
    <>
      <main
        className={classNames(
          'flex',
          'flex-col',
          className,
        )}
      >
        {props.children}
      </main>
      <Loader className='bg-white bg-opacity-90' center active={isLoading}/>
    </>
  )
}
