import React from 'react'
import classNames from 'classnames'

import { ReactComponent as Tail } from 'assets/tail-spin.svg'


export default function Loader(props) {
  if ( ! props.active) {
    return null
  }

  return (
    <div
      role={'progressbar'}
      className={classNames(
        props.className,
        {'flex items-center justify-center top-0 left-0 w-full h-full fixed': props.center},
        'z-50',
      )}
    >
      <div className='bg-primary rounded-full p-2'>
        <Tail />
      </div>
    </div>
  )
}
