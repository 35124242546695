import React from 'react'
import { useClickAway } from 'react-use'


export default function Message(props) {
  const ref = React.useRef(null)
  useClickAway(ref, () => {
    if (props?.onClose) {
      props.onClose()
    }
  })

  if (! props.active) {
    return null
  }

  return (
    <div ref={ref} className='p-2 sm:p-9 uppercase text-black bg-yellow text-lg sm:text-2xl text-center'>
      {props.children}
    </div>
  )
}
