import classNames from 'classnames';
import propTypes from 'prop-types'


export default function Buttonless(props) {
  return (
    <button
        id={props.id}
        className={classNames(
            props.className,
            'transition-all',
        )}
        disabled={props.disabled}
        onClick={props.onClick}
    >
        {props.children}
    </button>
  )
}

Buttonless.propTypes = {
  disabled: propTypes.bool,
  className: propTypes.string,
  onClick: propTypes.func,
}
