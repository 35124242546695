import React, {useState} from "react";
import TilesBoard from "./TilesBoard";

export default function TilesGame(props) {
    //State variables
    const [_currentSelection, _setCurrentSelection] = useState({cell1_id: null, cell2_id: null})
    const [_lastPaired, _setLastPaired] = useState({cell1_id: null, cell2_id: null, success: false})

    const resetLastPaired = () => {
        _setLastPaired({cell1_id: null, cell2_id: null, success: false})
    }

    const handleClickHexagon = (cell_id) => {
        let currentSelection = Object.assign({}, _currentSelection)
        let currentGameData = Object.assign({}, props._currentGameData)

        //Check if current selection is already paired
        if (currentGameData.cells[cell_id].paired) {
            resetLastPaired()
            return;
        }

        //Check if not exists a previous selection
        if (currentSelection.cell1_id === null) {
            currentSelection.cell1_id = cell_id
            currentGameData.cells[cell_id].selected = true
            _setCurrentSelection(currentSelection)
            props._setCurrentGameData(currentGameData)
            resetLastPaired()
            return;
        }

        //Check if current selection  is the same than previous selection
        if (currentSelection.cell1_id === cell_id) {
            currentSelection.cell1_id = null
            currentGameData.cells[cell_id].selected = false
            _setCurrentSelection(currentSelection)
            props._setCurrentGameData(currentGameData)
            resetLastPaired()
            return;
        }

        currentGameData.cells[cell_id].selected = true
        currentSelection.cell2_id = cell_id
        _setCurrentSelection(currentSelection)
        props._setCurrentGameData(currentGameData)

        props.onAddTry()

        //Check if selected cells are paired
        if (currentGameData.cells[currentSelection.cell1_id].tileId === currentGameData.cells[cell_id].tileId) {
            currentGameData.cells[currentSelection.cell1_id].paired = true
            currentGameData.cells[cell_id].paired = true

            props.addResolvedPair(currentSelection)

            _setLastPaired({cell1_id: currentSelection.cell1_id, cell2_id: cell_id, success: true})
        } else {
            _setLastPaired({cell1_id: currentSelection.cell1_id, cell2_id: cell_id, success: false})
        }

        currentGameData.cells[currentSelection.cell1_id].selected = false
        currentGameData.cells[cell_id].selected = false
        props._setCurrentGameData(currentGameData)

        _setCurrentSelection({cell1_id: null, cell2_id: null})
    }

    // const proposePair = () => {
    //     let cell1 = props._currentGameData.cells.find(element => !element.paired)
    //     let cell2 = props._currentGameData.cells.find(element => element.position !== cell1.position && element.tileId === cell1.tileId)
    //     return {
    //         cell1_id: cell1.position,
    //         cell2_id: cell2.position
    //     }
    // }

    return (
        <TilesBoard
            board={props.board}
            _currentGameData={props._currentGameData}
            onClick={handleClickHexagon}
            isFinished={props.isFinished}
            _lastPaired={_lastPaired}
        />
    )
}
