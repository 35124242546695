import classNames from 'classnames'
import React from 'react'


function Cell({
  status = null,
  letter = '',
  delayIndex = 0,
  notFound = false,
  className = '',
}) {
  return (
    <div
      className={classNames(
        'border border-disabled-lighter transition-all w-10 h-10 md:w-14 md:h-14',
        'flex items-center justify-center text-2xl md:text-3xl',
        {
          'animate-vibrate': notFound,
          '!bg-green-opaque animate-zoom-out-in': status === 'wrong',
          '!bg-green animate-zoom-in-out': status === 'exact',
          '!bg-yellow animate-vibrate': status === 'included',
        },
        className,
      )}
      style={{
        animationDelay: `${delayIndex / 10}s`,
      }}
    >
      {letter}
    </div>
  )
}

export default Cell
