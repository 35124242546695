import React, {useState} from "react";
import DifferencesModalSuccess from "./DifferencesModalSuccess";
import DifferencesModalError from "./DifferencesModalError";
import './DifferencesBoard.css'
import I18n from "../../../lang";

export default function DifferencesBoard(props) {
    const [_showClickOtherImage, _setShowClickOtherImage] = useState(false)
    const [_imgLoaded, _setImgLoaded] = useState(false)

    const findPosition = (oElement) => {
        if (typeof (oElement.offsetParent) != "undefined") {
            for (var posX = 0, posY = 0; oElement; oElement = oElement.offsetParent) {
                posX += oElement.offsetLeft;
                posY += oElement.offsetTop;
            }
            return [posX, posY];
        } else {
            return [oElement.x, oElement.y];
        }
    }

    const handleImageClick = (event) => {
        if (props.isFinished) {
            return
        }

        let posX = 0;
        let posY = 0;
        let imgPos;
        let myImg = document.getElementById("myImg");

        imgPos = findPosition(myImg);

        if (event.pageX || event.pageY) {
            posX = event.pageX;
            posY = event.pageY;
        } else if (event.clientX || event.clientY) {
            posX = event.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
            posY = event.clientY + document.body.scrollTop + document.documentElement.scrollTop;
        }

        posX = posX - imgPos[0];
        posY = posY - imgPos[1];

        let imgWidth = myImg.width;
        let imgHeight = myImg.height;

        let posXPercent = posX / imgWidth
        let posYPercent = posY / imgHeight

        props.handleClickImage({
            x: posXPercent,
            y: posYPercent
        })
    }

    const drawCircles = (diffPoints) => {
        if(!_imgLoaded) {
            return
        }

        let canvas = document.getElementById('myCanvas');
        if (null === canvas) {
            return
        }
        let myImg = document.getElementById("myImg");
        canvas.width = canvas.offsetWidth;
        canvas.height = canvas.offsetHeight;

        let ctx = canvas.getContext("2d");
        ctx.lineWidth = 4;
        ctx.strokeStyle = '#30963f';

        diffPoints.forEach((point) => {
            if (point.discovered || props._gameState?.estadouser?.id === 3) {
                let x = point.x * canvas.width
                let y = point.y * canvas.height

                ctx.beginPath();
                ctx.arc(x, y, 30, 0, 2 * Math.PI);
                ctx.stroke();
            }
        })
    }

    React.useEffect(() => {
        if(_imgLoaded) {
            drawCircles(props._gameState?.estadouser?.id === 3 ? props._gameState.puntos : props._currentGameData.diffPoints)
        }
    }, [_imgLoaded])

    drawCircles(props._gameState?.estadouser?.id === 3 ? props._gameState.puntos : props._currentGameData.diffPoints)

    return (
        <>
            <div className="p-4 pt-0 pb-4 flex flex-shrink md:flex-row flex-col diff-wrapper">
                <div className="flex flex-1">
                    <div className='diff-outside-wrapper'>
                        <div className='diff-inside-wrapper'>
                            <img
                                className="diff-img object-cover overflow-hidden"
                                src={props._currentGameData.img1}
                                alt='differences game board'
                                onClick={() => _setShowClickOtherImage(true)}
                            />
                            {
                                _showClickOtherImage === true &&
                                <DifferencesModalError
                                    showSuccessModal={(success) => _setShowClickOtherImage(success)}
                                    text={<I18n t='game.differencesGame.clickOtherImage'/>}
                                />
                            }
                        </div>
                    </div>
                </div>
                <div className="flex flex-1">
                    <div className='diff-outside-wrapper'>
                        <div className='diff-inside-wrapper'>
                            <img
                                id='myImg'
                                className="diff-img object-cover overflow-hidden"
                                src={props._currentGameData.img2}
                                alt='differences game diff board'
                                onLoad={() => _setImgLoaded(true)}
                            />
                            <canvas
                                id='myCanvas'
                                className='diff-img diff-canvas'
                                onClick={handleImageClick}
                            />
                            {
                                props._success.show === true &&
                                (props._success.success ?
                                    <DifferencesModalSuccess
                                        showSuccessModal={props.showSuccessModal}
                                    />
                                    :
                                    <DifferencesModalError
                                        showSuccessModal={props.showSuccessModal}
                                        text={<I18n t='game.differencesGame.incorrectHit'/>}
                                    />)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
