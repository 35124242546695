import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import moment from 'moment'

import Header from 'layouts/Header'
import Layout from 'layouts/Layout'
import { AlertButton } from 'layouts/Game/messages'
import Game, { STATUS_FINISHED, STATUS_PLAYING } from 'layouts/Game'
import Timer from 'components/Timer'
import useGame, { STATUS_INCOMPLETED, TRANSLATE_STATUS } from 'hooks/useGame'
import gamesApi, { gamesApiBase, getUserId } from 'api'
import I18n from 'lang'
import useGameStore from 'store'
import TilesGame from './TilesGame'
import Board from '../boards/board1.json'

import { ReactComponent as EmojiSmile } from 'assets/emoji-smile-fill.svg'
import { ReactComponent as EmojiSad } from 'assets/testactualidad-emoji-frown-fill.svg'

import './TilesApp.css'


export default function TilesApp({
  gameProps,
}) {
  const { id = 'last', locale = 'es' } = useParams()

  const {
    title,
    color: bgColor,
    api: {
      basepath: baseUrlApi,
    },
    icon: {
      big: bigIconSrc,
    },
    url: baseUrl,
  } = gameProps


  const _resolvedPairs = React.useRef([])
  const [_userTries, _setUserTries] = useState(0)
  const [_numBoardPairs, _setNumBoardPairs] = useState(Board.cells.length / 2)
  const [_isInitialized, _setIsInitialized] = useState(false)
  const [_gameState, _setGameState] = useState({})
  const [_currentGameData, _setCurrentGameData] = useState({})
  const [_statistics, _setStatistics] = useState([])
  const [_showHowToPlayMessage, _setShowHowToPlayMessage] = useState(false)

  const resetEndGameModal = useCallback((tiempo=4*60, promediogeneral=0) => ({
    content: (
      <div className='flex items-center space-x-5 justify-center text-yellow'>
        <EmojiSmile className='w-[75px] h-[75px] rounded-full' />
        <div className='text-3xl uppercase'>
          <I18n t='game.messages.complete.title' />
        </div>
      </div>
    ),
    statistics: [
      {
        i18nKey: 'game.messages.complete.completedTime',
        value: moment(tiempo * 1000).format('mm:ss'),
      },
      {
        i18nKey: 'game.messages.complete.averageTime',
        value: moment(promediogeneral * 1000).format('mm:ss'),
      },
    ],
  }), [])

  const [messageEndGame, setMessageEndGame] = useState(resetEndGameModal(
    _gameState?.estadouser?.tiempo,
    _gameState?.promediogeneral
  ))

  const setToast = useGameStore((store) => store.setToast)

  const {
    isLoading,
    status,
    initGame,
    setPause,
    setPlaying,
    save,
    restart,
    finish,
    resolveForce,
    time,
    message,
    modal,
    showEndGameModal,
    setShowEndGameModal,
  } = useGame({
    decreasing: true,
    onTimeEnd: async () => {
      await resolveForce(baseUrlApi, getCurrentDataForApi())
      await restartAllData()
      setMessageEndGame({
        content: (
          <div className='flex flex-col items-center space-y-5 justify-center text-yellow'>
            <EmojiSad className='w-[75px] h-[75px] rounded-full' />
            <div className='text-center'>
              <div className='text-3xl uppercase'>
                <I18n t='game.tilesGame.incomplete.title' />
              </div>
              <div className='text-2xl uppercase'>
                <I18n t='game.tilesGame.incomplete.message' />
              </div>
            </div>
          </div>
        ),
        statistics: [],
      })
      setTimeout(() => setShowEndGameModal(true), 10)
    },
    startFrom: [TRANSLATE_STATUS[STATUS_FINISHED], TRANSLATE_STATUS[STATUS_INCOMPLETED]].includes(_gameState?.estadouser?.id) ? 0 : parseInt(_gameState?.estadouser?.tiempo || 4*60, 10),
    expirationDate: _gameState?.despublicado,
    locale: locale,
    statusInit: _gameState?.estadouser?.id,
  })

  //////INIT
  const initCurrentGameData = (_gameState) => {
    let cells = []
    _gameState.tablero.forEach((cell) => {
      cells[cell.posicion] = {
        position: cell.posicion,
        tileId: cell.baldosa_id,
        rotation: cell.rotacion,
        selected: false,
        paired: false,
        lastPaired: false,
      }
    })

    let resolvedPairs = []
    _gameState.estadouser.parejas_resueltas?.forEach((pair) => {
      cells[pair.cell1_id].paired = true
      cells[pair.cell2_id].paired = true
      resolvedPairs.push({
        cell1_id: pair.cell1_id,
        cell2_id: pair.cell2_id
      })
    })
    addResolvedPair(resolvedPairs)

    let currentGameData = {
      cells: cells,
      board: Board
    }
    _setCurrentGameData(currentGameData)

    _setUserTries(_gameState.estadouser.clicks === undefined ? 0 : parseInt(_gameState.estadouser.clicks))

    _setIsInitialized(true)
  }

  React.useEffect(() => {
    if (status === STATUS_PLAYING) {
      switch (time) {
        case 4*60:
          setToast({
            id: 'baldosas_init',
            message: I18n.getTranslation({pathname: '/' + locale,}, 'game.tilesGame.messages.init'),
          })
          break
        case 3*60:
          setToast({
            id: 'baldosas_3m',
            message: I18n.getTranslation({pathname: '/' + locale,}, 'game.tilesGame.messages.3m'),
          })
          break
        case 2*60:
          setToast({
            id: 'baldosas_2m',
            message: I18n.getTranslation({pathname: '/' + locale,}, 'game.tilesGame.messages.2m'),
          })
          break
        case 1*60:
          setToast({
            id: 'baldosas_1m',
            message: I18n.getTranslation({pathname: '/' + locale,}, 'game.tilesGame.messages.1m'),
          })
          break
        case 30:
          setToast({
            id: 'baldosas_30s',
            message: I18n.getTranslation({pathname: '/' + locale,}, 'game.tilesGame.messages.30s'),
          })
          break
        case 10:
          setToast({
            id: 'baldosas_10s',
            message: I18n.getTranslation({pathname: '/' + locale,}, 'game.tilesGame.messages.10s'),
          })
          break
        default:
          break
      }
    }
  }, [time, setToast, locale, status])

  //////FUNCTIONS
  const fetchStatistics = async () => {
    const url = `/user/stats/${baseUrlApi}`.replace(/\/+/g, '/')
    const response = await gamesApi.post(url)

    _setStatistics(response.data)
  }

  const fetchGame = async () => {
    _setIsInitialized(false)
    const response = await gamesApi.get(`${baseUrlApi}/get/${id}`.replace(/\/+/g, '/'))
    const dataFetch = response.data

    initCurrentGameData(dataFetch)

    _setGameState(dataFetch)
    setMessageEndGame(resetEndGameModal(dataFetch.estadouser.tiempo, dataFetch.promediogeneral))
  }

  const restartAllData = async () => {
    fetchStatistics()
    _setUserTries(0)
    _setIsInitialized(false)
    _setGameState({})
    _setCurrentGameData({})
    _setStatistics([])
    _resolvedPairs.current = []
    _setNumBoardPairs(Board.cells.length / 2)
    await initGame(async () => await fetchGame())
  }

  React.useEffect(() => {
    restartAllData()
  }, [id]) // eslint-disable-line

  //When TilesGame.state._resolvedPairs.current changes...
  const addResolvedPair = (resolvedPair) => {
    let resolvedPairs = Object.assign([], _resolvedPairs.current)

    if (resolvedPair instanceof Array) {
      resolvedPair.forEach((pair) => {
        resolvedPairs.push(pair)
      })
    } else {
      resolvedPairs.push(resolvedPair)
    }
    _resolvedPairs.current = resolvedPairs
  }

  const gameIsFinished = () => {
    return _currentGameData.cells.length / 2 === _resolvedPairs.current.length
  }

  useEffect(() => {
    if (_isInitialized) {
      if (gameIsFinished()) {
        onFinishedGame()
      }
    }
  }, [_resolvedPairs.current]) // eslint-disable-line

  const getCurrentDataForApi = () => {
    return ({
      gameid: _gameState.id,
      gamedata: {
        tiempo: time,
        clicks: _userTries,
        parejas_resueltas: _resolvedPairs.current
      }
    })
  }

  const saveGame = async (exit = false, newUrl = null) => {
    await save(
      baseUrlApi,
      getCurrentDataForApi(),
      exit,
      newUrl,
    )
    fetchStatistics()
  }

  // When TilesGame finish launch a signal
  const onFinishedGame = async () => {
    await finish(baseUrlApi, getCurrentDataForApi())
    fetchStatistics()
    await fetchGame()
  }

  const onRestartGameClick = async () => {
    await restart(
      baseUrlApi,
      {
        gameid: _gameState.id,
      },
    )
    restartAllData()
  }

  const onHowToPlayClick = () => {
    _setShowHowToPlayMessage(true)
  }

  const helpMenu = [
    {
      href: '',
      label: <I18n t='game.actions.help'/>,
      options: [
        {
          href: '',
          onClick: onRestartGameClick,
          disabled: status !== STATUS_PLAYING,
          label: <I18n t='game.differencesGame.restartGame'/>,
        },
        {
          href: '',
          onClick: onHowToPlayClick,
          disabled: status !== STATUS_PLAYING,
          label: <I18n t='game.differencesGame.howToPlay'/>,
        },
      ]
    },
  ]

  const mobileMenu = [
    {
      href: '',
      onClick: onRestartGameClick,
      disabled: status !== STATUS_PLAYING,
      label: <I18n t='game.differencesGame.restartGame'/>,
    },
    {
      href: '',
      onClick: onHowToPlayClick,
      disabled: status !== STATUS_PLAYING,
      label: <I18n t='game.differencesGame.howToPlay'/>,
    }
  ]

  const formatDate = (date) => {
    let dateText = moment(date).locale(locale).format('dddd, DD.MM.YY')
    return dateText.charAt(0).toUpperCase() + dateText.slice(1)
  }

  const onAddTry = () => {
    _setUserTries(_userTries+1)
  }

  React.useEffect(() => {
    if (status === STATUS_PLAYING) {
      saveGame()
    }
  }, [status, _userTries]) // eslint-disable-line

  return (
    <Layout isLoading={isLoading}>
      <Header/>

        {
          _isInitialized &&
          <Game
            statsUrl={`/user/stats/${baseUrlApi}`.replace(/\/+/g, '/')}
            endGameModal={{
              isOpen: showEndGameModal,
              title,
              icon: bigIconSrc,
              titleBgColor: bgColor,
              onDate: formatDate(_gameState?.publicado),
              ...messageEndGame,
              shareUrl: `${gamesApiBase}user/stats/baldosas/share?userid=${getUserId()}&id=${_gameState?.id}`,
              onClose: () => setShowEndGameModal(false),
            }}
            status={status}
            statistics={_statistics}
            expirationDate={formatDate(_gameState?.despublicado)}
            historicalGames={{
              active: !isLoading,
              url: `${baseUrlApi}/getlist`.replace(/\/+/g, '/'),
              activeId: _gameState?.id,
              to: baseUrl,
              icon: bigIconSrc,
            }}

            publicationDate={formatDate(_gameState?.publicado)}
            title={title}
            saveGame={() => saveGame()}
            onClickOutside={(newUrl) => saveGame(true, newUrl)}
            exitGame={status !== STATUS_FINISHED ? e => { saveGame(true); e.preventDefault() } : false}
            helpMenu={mobileMenu}
            leftMenu={helpMenu}
            middleMenu={
              <Timer
                setPause={setPause}
                setPlaying={setPlaying}
                status={status}
                time={time}
              />
            }
          >

          {message}

          <div className='relative flex justify-center md:px-12 md:pt-12'>

            {
              _showHowToPlayMessage === true &&
              <AlertButton
                active={_showHowToPlayMessage}
                messageI18n={'game.tilesGame.howToPlayTextFull'}
                messageArgs={{}}
                buttonPrimaryI18n={'game.messages.complete.button'}
                onClickPrimary={() => {_setShowHowToPlayMessage(false)}}
                messageKey={''}
              />
            }

            <TilesGame
              _currentGameData={_currentGameData}
              _setCurrentGameData={_setCurrentGameData}
              board={Board}
              isFinished={[STATUS_FINISHED, STATUS_INCOMPLETED].includes(status)}
              addResolvedPair={addResolvedPair}
              onAddTry={onAddTry}
            />

          </div>
          <div className='flex flex-col justify-center items-center mb-10'>
            <div className='uppercase text-lg text-center mb-2'>
              <I18n t='game.tilesGame.marker'/>
            </div>
            <div className='flex justify-center space-x-2'>
              <div className='text-center w-20'>
                <div className='text-4xl text-white bg-[#39A351] text-center'>
                  {_resolvedPairs.current.length}
                </div>
                <div className='text-center text-sm'>
                  <I18n t='game.tilesGame.resolvedPairs'/>
                </div>
              </div>
              <div className='text-center w-20'>
                <div className='text-4xl text-white bg-disabled-lighter text-center'>
                  {_numBoardPairs}
                </div>
                <div className='text-center text-sm'>
                  <I18n t='game.tilesGame.totalPairs'/>
                </div>
              </div>
            </div>
          </div>


          <div className='flex justify-center pb-10'>
            <div className='sm:max-w-sm px-5'>
              <div className="help-h3">
                <h3><I18n t='game.tilesGame.howToPlay'/></h3>
              </div>
              <div className="help-p">
                <I18n t='game.tilesGame.howToPlayText'/>
              </div>
            </div>
          </div>

          {modal}
        </Game>
      }
    </Layout>
  )
}
