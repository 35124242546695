import create from 'zustand'

import {
  STATUS_INIT,
  STATUS_PAUSED,
  STATUS_PLAYING,
  // STATUS_SAVED,
  // STATUS_SAVE,
  // STATUS_SAVELEXIRETO,
  STATUS_FINISHED,
  // STATUS_INCOMPLETED,
  // STATUS_ERROR,
  // STATUS_PAST,
} from 'hooks/useGame'


const useGameStore = create((set, get) => ({
  status: 'init',
  time: 0,
  timeInterval: null,
  reset: () => {
    if (get().timeInterval)
      clearInterval(get().timeInterval)

    set({ time: 0 })
  },
  play: () => {
    if (get().timeInterval) {
      clearInterval(get().timeInterval)
    }
    const timeInterval = setInterval(() => {
      set({ time: get().time + 1 })
    }, 1000)
    set({
      timeInterval,
      status: STATUS_PLAYING
    })
  },
  pause: () => {
    clearInterval(get().timeInterval)
    set({
      status: STATUS_PAUSED,
    })
  },
  finish: (status=STATUS_FINISHED) => {
    clearInterval(get().timeInterval)
    set({
      status,
    })
  },
  toastInterval: {
    top: null,
    bottom: null,
  },
  toasts: {
    top: [],
    bottom: [],
  },
  setToast: (toast) => {
    const position = toast.position || 'bottom'
    set({
      toasts: {
        [position]: [...get().toasts[position], toast]
      }
    })
    get().toastInterval[position] = setTimeout(() => {
      set({
        toasts: {
          [position]: get().toasts[position].slice(1)
        }
      })
    }, 3000)
  }
}))

export default useGameStore
