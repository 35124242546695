import create from 'zustand'

import gamesApi from 'api'
import useGameInfoStore from 'store/game'


const useNavStore = create((set, get) => ({
  items: {},
  getNavigation: async () => {
    const { items: games } = useGameInfoStore.getState()

    const navResponse = (await gamesApi.get('/config/nav')).data

    set({
      items: navResponse.map(
        (item) => {
          if (item.gameId !== null) {
            return games.find(game => game.id === item.gameId) || {}
          }

          return {
            title: item.title,
            children: item.children.map(
              (itemChild) => ({
                ...games.find(game => game.id === itemChild.gameId)
              })
            )
          }
        }
      )
    })
  },
}))

export default useNavStore
