import { createI18n } from 'react-router-i18n'

import resources from 'lang/resource.js'


const locales = ['es', 'ca', 'en']


const I18n = createI18n(
  locales,
  resources,
)

export default I18n
