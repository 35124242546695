import {getCompletePathname} from "../../../config";

export default function TilesHexagonTransition(props) {
    const tilePath1 = getCompletePathname(`/assets/tilesGame/tile-${props.tileId1 + 1}-${props.success ? 'success' : 'error'}.svg`)
    const tilePath2 = getCompletePathname(`/assets/tilesGame/tile-${props.tileId2 + 1}-${props.success ? 'success' : 'error'}.svg`)

    return (
        // props.tileId must be a value between 0 and 17
        // rotation must be a numeric value between 30, 90, 150, 210, 270, 330
        <>
            <svg
                className={'tiles-fade-out'}
                x={props.x1 + '%'}
                y={props.y1 + '%'}
            >
                <image
                    href={tilePath1}
                    transform={`rotate(${props.rotation1 + props.additionalRotation} 141.73 141.73)`}/>
            </svg>

            <svg
                className={'tiles-fade-out'}
                x={props.x2 + '%'}
                y={props.y2 + '%'}
            >
                <image
                    href={tilePath2}
                    transform={`rotate(${props.rotation2 + props.additionalRotation} 141.73 141.73)`}/>
            </svg>
        </>
    )
}