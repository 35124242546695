const DifferencesGameLocaleES = {
    title: "Los 8 Errores",
    hits: "aciertos",
    clicks: "clics",
    resolveGame: "Resolver",
    restartGame: "Reiniciar",
    howToPlay: "Cómo se juega",
    howToPlayText: () => <>Encuentra y señala los 8 errores en el segundo dibujo. <br/>Tienes 12 oportunidades.</>,
    howToPlayTextFull:
        () =>
            <>
                <strong>Cómo se juega</strong>
                <br />
                Encuentra y señala los 8 errores en el segundo dibujo. <br/>Tienes 12 oportunidades.
            </>,
    successfullyHit: "¡BIEN!",
    incorrectHit: "¡ERROR!",
    clickOtherImage: "Pulsa en el segundo dibujo",
    completadotitulo: "¡Juego completado!",
    textoacertado: "Clics acertados ",
    textoerroneos: ", clics erróneos ",
    textorevisarestadisticas: "Puedes revisar en Mis estadísticas los resultados acumulados",
    statusEndGame: {
      good: '¡BRAVO!',
      neutral: '¡BIEN!',
      bad: '¡PUEDES MEJORAR!',
    }
    // description: ({ corrects, errors }) => <>Respuestas correctas {corrects}, incorrectas {errors}.<br />Puedes revisar en Mis estadísticas los resultados acumulados.</>,
    //  completado: ({acertados}) => <>Clics acertados {acertados}, clics erróneos erroneos.<br/>Puedes revisar en Mis estadísticas los resultados acumulados</>,

}

export default DifferencesGameLocaleES

