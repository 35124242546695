import LetrasConectadasCA from '../scenes/LetrasConectadas/locales/ca/translation.js';
import LetrasConectadasES from '../scenes/LetrasConectadas/locales/es/translation.js';
import TilesGameLocaleCA from  '../scenes/Tiles/locales/ca/translation.js';
import TilesGameLocaleES from  '../scenes/Tiles/locales/es/translation.js';
import DifferencesGameLocaleCA from '../scenes/Differences/locales/ca/translation.js';
import DifferencesGameLocaleES from '../scenes/Differences/locales/es/translation.js';
import LexiRetoGameLocaleES from  '../scenes/Lexireto/locales/es/translation.js';
import { IoMdShare } from 'react-icons/io'


const resource = {
  es: {
    gameTitles: {
      'crucigrama': 'Crucigrama',
      'lexireto': 'Lexireto',
      'diferencias': 'Diferencias',
      'sudokus': 'Sudokus',
      'sudoku-facil': 'Sudoku facil',
      'sudoku-medio': 'Sudoku medio',
      'sudoku-avanzado': 'Sudoku avanzado',
      'mini-sudoku': 'Mini sudoku',
      'quiz': 'Quiz',
      'quiz-historia-y-geografia': 'Historia y geografia',
      'quiz-lengua-y-literatura': 'Lengua y literatura',
      'quiz-deportes': 'Deportes',
      'quiz-ciencia': 'Ciencia',
      'quiz-arte-y-espectaculos': 'Arte y espectaculos',
      'letras': 'Letras',
      'reto-actualidad': 'Reto actualidad',
      'baldosas': 'Baldosas',
      'mots-encreuats': 'Mots encreuats',
      'cruciveloz': 'Cruciveloz',
      'fastcrossword': 'Fastcrossword',
    },
    close: 'Cerrar',
    by: 'Por',
    play: 'Juega',
    game: {
      historicalGames: {
        playing: 'EN JUEGO',
      },
      new: 'Nuevo',
      actions: {
        save: 'Guardar',
        download: 'Descargar',
        exit: 'Salir',
        help: 'Ayuda'
      },
      timer: {
        show: 'Mostrar',
        hide: 'Ocultar',
      },
      yourPoints: 'Tus Puntos',
      heptacracks: 'Heptacracks',
      yourWords: 'Tus palabras',
      yourAnswers: 'Tus respuestas',
      tusclics: 'Tus clics',
      messages: {
        init: {
          message: '¿Estás preparado para empezar?',
          button: 'Jugar',
        },
        pause: {
          message: 'El juego está en pausa',
          button: 'Continuar',
        },
        restart: {
          message: 'Estás a punto de volver a empezar el juego',
          buttonPrimary: 'Reiniciar',
          buttonCancel: 'Volver',
        },
        resolve: {
          message: 'Si eliges RESOLVER, este juego quedará reflejado como incompleto en Mis estadísticas. ¿Estás conforme?',
          buttonPrimary: 'Si',
          buttonCancel: 'No',
        },
        save: {
          message: 'Estás a punto de salir del juego. ¿Quieres guardar los cambios?',
          buttonPrimary: 'Si',
          buttonCancel: 'No',
        },
        errorSaving: {
          message: 'Error al guardar. Intentelo nuevamente.',
          button: 'Cerrar',
        },
        errorRestarting: {
          message: 'Error al reiniciar el juego. Intentelo nuevamente.',
          button: 'Cerrar',
        },
        errorResolving: {
          message: 'Error al resolver el juego. Intentelo nuevamente.',
          button: 'Cerrar',
        },
        errorFinishing: {
          message: 'Error al finalizar el juego. Intentelo nuevamente.',
          button: 'Cerrar',
        },
        errorChecking: {
          message: '¡Lástima! No has completado el juego correctamente. Sigue probando.',
          button: 'Cerrar',
        },
        finished: {
          message: 'Ya has completado este juego. Solo puedes consultarlo.',
          button: 'Volver',
        },
        complete: {
          title: '¡Bravo!',
          endGameTitle: '¡JUEGO TERMINADO!',
          averageTime: 'Tiempo promedio general',
          completedTime: 'Juego completado en',
          share: 'Comparte tus resultados',
          shortDescription: 'Comprueba si has mejorado en Mis estadísticas.',
          description: 'Has terminado el juego. Comprueba si has mejorado en Mis estadísticas.',
          button: 'Cerrar',
          onDate: (date) => <>Juego del {date}</>,
        },
        incomplete: {
          message: 'Aquí tienes el juego resuelto. En Mis estadísticas se contabilizará como incompleto.',
        },
        past: {
          message: 'Juego terminado. Solo puedes consultarlo.',
          button: 'Volver',
        },
        saved: {
          // message: 'Ya no es necesario utilizar el botón Guardar. Cada vez que hagas un avance, el juego quedará automáticamente guardado.',
          message: 'El juego se ha guardado correctamente',
          button: 'Salir',
        },
        savedlexireto: {
          message: ({ date }) => <>El juego se ha guardado correctamente.</>,
          button: 'Salir',
        }
      },
      currentChallenge: {
        actions: {
          next: 'Siguiente',
          showExplain: 'Ver explicación',
          finish: 'Finalizar',
        },
        messages: {
          finish: {
            title: '¡Test completado!',
            description: ({ corrects, errors }) => <>Respuestas correctas {corrects}, incorrectas {errors}.<br />Puedes revisar en Mis estadísticas los resultados acumulados.</>,
            button: 'Cerrar',
          },
          showExplain: 'El porqué de la respuesta',
        },
        statusEndGame: {
          good: '¡BRAVO!',
          neutral: '¡BIEN!',
          bad: '¡PUEDES MEJORAR!',
        },
      },
      crosswords: {
        questions: 'Preguntas Crucigrama',
        horizontals: 'Horizontales',
        verticals: 'Verticales',
        horizontal: 'Horizontal',
        vertical: 'Vertical',
        messages: {
          finish: {
            title: '¡Bravo!',
            description: 'Vas acabar els teus mots encreuats.\nComprova si vas millorar en les estadístiques.',
            button: 'Salir',
          },
          incomplete: {
            title: '',
            description: 'Aquí tens els mots encreuats resolts. A Les meves estadístiques es comptabilitzaran com a incomplets.',
            button: 'Salir',
          },
        },
        actions: {
          checkLetter: 'Comprobar letra',
          checkWord: 'Comprobar palabra',
          checkCrossword: ({ gameName }) => <>Comprobar {gameName}</>,
          resolveCrossword: 'Resolver',
          restartCrossword: 'Reiniciar',
        }
      },
      sudoku: {
        levels: {
          easy: 'Básico',
          medium: 'Avanzado',
          hard: 'Experto',
        },
        messages: {
        },
        actions: {
          checkCell: 'Comprobar casilla',
          checkSudoku: ({gameName}) => <>Comprobar {gameName}</>,
          resolveSudoku: 'Resolver',
          restartSudoku: 'Reiniciar',
        }
      },
      letrasConectadasGame: LetrasConectadasES,
      tilesGame: TilesGameLocaleES,
      differencesGame: DifferencesGameLocaleES,
      LexiRetoGame: LexiRetoGameLocaleES,

      buttons: {
        statistics: 'Mis estadísticas',
        share: () => <><IoMdShare /> <span>Compartir</span></>,
        shareLabel: 'Compartir',
      },
      words: {
        howToPlay: 'Cómo se juega',
        howToPlayContent: 'Descubre la palabra oculta en 6 intentos. Cada intento debe ser una palabra válida de 5 letras. Después de cada intento el color de las letras te ofrece pistas sobre la palabra oculta. No se admiten nombre propios, plurales y formas verbales conjugadas (solo intinitivos)',
        examples: {
          title: 'Ejemplos',
          first: {
            word: 'COBRE',
            explanation: 'La letra C está en la palabra y en la posición correcta.',
          },
          second: {
            word: 'MARZO',
            explanation: 'La letra R está en la palabra pero en la posición incorrecta.',
          },
          third: {
            word: 'VERDE',
            explanation: 'La letra E no está en la palabra.',
          },
        },
        footer: '¡UNA PALABRA NUEVA CADA DÍA!',
        disclaimer: 'WordleMente es un una adaptación de Quality Games del juego original creado por Johs Wardle (@powerlanguish) realizado en Open source (veure casos al final del mail)',
      },
    },
  },

  ca: {
    close: 'Tancar',
    by: 'Per',
    play: 'Juga',
    game: {
      historicalGames: {
        playing: 'EN JOC',
      },
      new: 'Nou',
      actions: {
        save: 'Guardar',
        download: 'Descarregar',
        exit: 'Sortir',
        help: 'Ajuda'
      },
      timer: {
        show: 'Mostrar',
        hide: 'Amagar',
      },
      yourPoints: 'Your Points',
      yourAnswers: 'Your Answers',
      heptacracks: 'Heptacracks',
      yourWords: 'Your Words',
      messages: {
        init: {
          message: 'Estàs preparat per començar?',
          button: 'Jugar',
        },
        pause: {
          message: 'El joc està en pausa',
          button: 'Continuar',
        },
        restart: {
          message: 'Estàs a punt de tornar a començar el joc.',
          buttonPrimary: 'Reiniciar',
          buttonCancel: 'Tornar',
        },
        save: {
          message: 'Estàs a punt de sortir del joc. Vols guardar els canvis?',
          buttonPrimary: 'Si',
          buttonCancel: 'No',
        },
        resolve: {
          message: 'Si tries RESOLDRE, aquest joc quedarà reflectit com a incomplet a Les meves estadístiques. Estàs d’acord?',
          buttonPrimary: 'Si',
          buttonCancel: 'No',
        },
        errorChecking: {
          message: 'Llàstima! No has completat el joc correctament. Continua provant.',
          button: 'Tancar',
        },
        finished: {
          message: 'Ja has completat aquest joc. Només ho pots consultar.',
          button: 'Tancar',
        },
        incomplete: {
          message: 'Aquí tens el joc resolt. A Les meves estadístiques es comptabilitzarà com a incomplet.',
        },
        complete: {
          title: 'Molt bé!',
          endGameTitle: '¡JUEGO TERMINADO!',
          averageTime: 'Tiempo promedio general',
          completedTime: 'Juego completado en',
          share: 'Comparteix els teus resultats',
          shortDescription: 'Comprova si has millorat a Les meves estadístiques.',
          description: 'Has acabat el joc. Comprova si has millorat a Les meves estadístiques.',
          button: 'Tancar',
          onDate: (date) => <>Juego del {date}</>,
        },
        saved: {
          // message: 'Ja no cal utilitzar el botó Desa. Cada cop que facis un avenç, el joc quedarà automàticament guardat.',
          message: "El joc s'ha guardat correctament.",
          button: 'Tancar',
        }
      },
      crosswords: {
        questions: 'Preguntas Crucigrama',
        horizontals: 'Horitzontals',
        verticals: 'Verticals',
        horizontal: 'Horitzontal',
        vertical: 'Vertical',
        messages: {
          finish: {
            title: '¡Bravo!',
            description: 'Vas acabar els teus mots encreuats.\nComprova si vas millorar en les estadístiques.',
            button: 'Sortir',
          },
          incomplete: {
            title: '',
            description: 'Aquí tens els mots encreuats resolts. A Les meves estadístiques es comptabilitzaran com a incomplets.',
            button: 'Sortir',
          },
        },
        actions: {
          checkLetter: 'Comprovar lletra',
          checkWord: 'Comprovar paraula',
          checkCrossword: ({gameName}) => <>Comprovar {gameName}</>,
          resolveCrossword: 'Resoldre',
          restartCrossword: 'Reiniciar',
        }
      },
      letrasConectadasGame: LetrasConectadasCA,
      tilesGame: TilesGameLocaleCA,
      differencesGame: DifferencesGameLocaleCA,
      buttons: {
        statistics: 'Les meves estadístiques',
        share: () => <><IoMdShare /> <span>Compartir</span></>,
        shareLabel: 'Compartir',
      },
      words: {
        howToPlay: 'Cómo se juega',
        howToPlayContent: 'Descubre la palabra oculta en 6 intentos. Cada intento debe ser una palabra válida de 5 letras. Después de cada intento el color de las letras te ofrece pistas sobre la palabra oculta. No se admiten nombre propios, plurales y formas verbales conjugadas (solo intinitivos)',
        examples: {
          title: 'Ejemplos',
          first: {
            word: 'COBRE',
            explanation: 'La letra C está en la palabra y en la posición correcta.',
          },
          second: {
            word: 'MARZO',
            explanation: 'La letra R está en la palabra pero en la posición incorrecta.',
          },
          third: {
            word: 'VERDE',
            explanation: 'La letra E no está en la palabra.',
          },
        },
        footer: '¡UNA PALABRA NUEVA CADA DÍA!',
        disclaimer: 'WordleMente es un una adaptación de Quality Games del juego original creado por Johs Wardle (@powerlanguish) realizado en Open source (veure casos al final del mail)',
      },
    },
  },

  en: {
    close: 'Exit',
    by: 'By',
    play: 'Play',
    game: {
      historicalGames: {
        playing: 'PLAYING',
      },
      new: 'New',
      actions: {
        save: 'Save',
        download: 'Download',
        exit: 'Exit',
        help: 'Help'
      },
      timer: {
        show: 'Show',
        hide: 'Hide',
      },
      yourAnswers: 'Your Answers',
      yourPoints: 'Your Points',
      heptacracks: 'Heptacracks',
      yourWords: 'Your Words',
      messages: {
        init: {
          message: 'Are you ready to start?',
          button: 'Play',
        },
        pause: {
          message: 'The game is in pause',
          button: 'Continue',
        },
        restart: {
          message: 'You are going to restart the game',
          buttonPrimary: 'Restart',
          buttonCancel: 'Go back',
        },
        save: {
          message: "You're going out of the game. Do you want to save the changes?",
          buttonPrimary: 'Yes',
          buttonCancel: 'No',
        },
        resolve: {
          message: 'If you choose REVEAL, this game will be reflected as incomplete in My Stats. Do you agree?',
          buttonPrimary: 'Yes',
          buttonCancel: 'No',
        },
        errorChecking: {
          message: 'What a shame! You have not completed the game correctly. Keep trying.',
          button: 'Close',
        },
        finished: {
          message: 'You have already completed this game. You can only check it.',
          button: 'Close',
        },
        incomplete: {
          message: 'Here is the solved game. My statistics will be counted as incomplete.',
        },
        complete: {
          title: 'Good!',
          endGameTitle: 'GAME OVER!',
          averageTime: 'Overall average time',
          completedTime: 'Game completed in',
          description: "You have completed the game. See if you've improved in My Stats.",
          button: 'Close',
          shortDescription: "See if you've improved in My Stats.",
          share: 'Share your score',
          onDate: (date) => <>Game played on {date}</>,
        },
        saved: {
          message: 'The game has been saved successfully',
          button: 'Exit',
          // message: 'It is no longer necessary to use the Save button. Every time you make a El juego se ha guardado correctamente',
        }
      },
      crosswords: {
        questions: 'Preguntas Crucigrama',
        horizontals: 'Across',
        verticals: 'Down',
        horizontal: 'Across',
        vertical: 'Down',
        actions: {
          checkLetter: 'Check letter',
          checkWord: 'Check word',
          checkCrossword: ({gameName}) => <>Check {gameName}</>,
          resolveCrossword: 'Reveal',
          restartCrossword: 'Restart',
        }
      },
      buttons: {
        statistics: 'My stats',
        share: () => <><IoMdShare /> <span>Share</span></>,
        shareLabel: 'Share',
      },
      words: {
        howToPlay: 'Cómo se juega',
        howToPlayContent: 'Descubre la palabra oculta en 6 intentos. Cada intento debe ser una palabra válida de 5 letras. Después de cada intento el color de las letras te ofrece pistas sobre la palabra oculta. No se admiten nombre propios, plurales y formas verbales conjugadas (solo intinitivos)',
        examples: {
          title: 'Ejemplos',
          first: {
            word: 'COBRE',
            explanation: 'La letra C está en la palabra y en la posición correcta.',
          },
          second: {
            word: 'MARZO',
            explanation: 'La letra R está en la palabra pero en la posición incorrecta.',
          },
          third: {
            word: 'VERDE',
            explanation: 'La letra E no está en la palabra.',
          },
        },
        footer: '¡UNA PALABRA NUEVA CADA DÍA!',
        disclaimer: 'WordleMente es un una adaptación de Quality Games del juego original creado por Johs Wardle (@powerlanguish) realizado en Open source (veure casos al final del mail)',
      },
    },
  },

}


export default resource
