import React from 'react'
import { useParams } from 'react-router-dom'
import { useLocation } from 'react-use'
import useGameInfoStore from 'store/game'


export default function Title(props) {
  const {
    by,
    date,
    menu,
  } = props

  const location = useLocation()
  const { locale, id } = useParams()
  const gameInfoStore = useGameInfoStore()

  const pathname = location.pathname.replace(`/${id}`, '')
  const pathArray = pathname?.split('/')
  const index = pathArray.indexOf(locale)
  const path = pathArray.splice(index + 1, pathArray.length).join('/')

  const currentGame = gameInfoStore?.items?.find((game) => game.url === '/' + path)

  if (!currentGame) {
    return null
  }

  return (
    <div
      className='
        border-b sm:border-0
        py-4
        lg:py-5
        flex items-center
        bg-defaultGame
        text-center
        text-white
      '
      style={{
        backgroundColor: currentGame?.color,
      }}
    >
      <div className='flex-grow'>
        <h2 className='text-3xl sm:text-4xl mb-3 font-semibold titulojuego'>{currentGame.title}</h2>
        <p className='-mt-2 text-sm text-center text-white'>
          {
            by &&
            <span className='font-semibold hidden sm:inline'>{by} </span>
          }
          <span className='capitalize'>{date}</span>
        </p>
      </div>
      {
        menu &&
        <div>
          {menu}
        </div>
      }
    </div>
  )
}
