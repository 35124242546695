import React from "react";
import propTypes from "prop-types";

import "./Words.css";
import I18n from "../../../lang";
import CheckCircleFillIcon  from 'assets/check-circle-fill-green.svg'

export default function Words(props) {
    const {words} = props;

    const ul_list = (arr, nLetras) => {
        // let strEmpty = Array.from(new Array(nLetras + 1).join("_")).join("\u00a0");
        let strEmpty = Array.from(new Array(nLetras + 1).join("_"));
        let ul_array = [...arr].map((item, index) => {
            if (item === undefined) {
                return <li key={index} className="lc-li">{strEmpty}</li>;
            } else {

                //partimos la palabra en array de letras
                let split = item.split("");
                let primeralletra = split.slice(0,1);

                //obtenemos todas las letras menos la primera
                split = split.slice(1);

                // split = split.slice()
                // let lletra = "";
                // split.forEach(l =>  {
                //     console.log("index:");
                //     console.log(l);
                //      lletra += <div className="cuadrat">
                //          {l};
                //      </div>;
                // });
                //
                 let teparaula = false;
                 let icona = <div
                     className="w-4 h-4 rounded-full inline-flex bg-gray-100 items-center justify-center">
                 </div>;

                let icona_estil = 'lletrestrobades_icona_noactiva';

                 if (split.at(-1) != "_") {
                     teparaula = true;
                     icona =<img
                         src={CheckCircleFillIcon}
                         style={{ height: 14, width: 14    }}/>
                     icona_estil = 'lletrestrobades_icona';
                 }

                 let estillletres = "lletrestrobades-notrobades";
                 if (teparaula) {
                     estillletres = "lletrestrobades";
                 }


                const myList = (

                    <table className=" taulaparaulestrobades">
                        <tbody>
                        <tr>
                            <td className={icona_estil}>
                                <center>
                                    {icona}
                                </center>
                            </td>
                            <td className="lletrestrobades">
                                {primeralletra}
                            </td>
                            {split.map((item, i) =>
                                <td className={estillletres}>{item == "_"?"":item}</td>
                            )}
                        </tr>
                        </tbody>
                    </table>
                )


                return (
                    <li key={index} className="lc-li">
                            {/*{Array.from(lletra).join("\u00a0")}*/}
                            {/*{item}*/}
                        {/*{Array.from(item).join("\u00a0")}*/}
                            {myList}
                    </li>
                );
            }
        });
        return <ul className="lc-ul">{ul_array}</ul>;
    };

    return (
        <>
        <div className="grid grid-cols-1">
            <div className="lc-container-exercici-separacio mt-0 mb-0">
                {Object.keys(words).sort().reverse().map((key) => (
                    <div key={key} className="mb-11">
                        <h2 className="lc-container-exercici-llista lc-h2 mt-6 mb-2.5">
                            {words[key].length} de {key} <I18n t="game.letrasConectadasGame.messages.letters"/>
                        </h2>
                        {ul_list(words[key], parseInt(key))}
                    </div>
                ))}

                {/*<div className="col-2"></div>*/}
            </div>
        </div>
        </>
    );
}

Words.propTypes = {
    words: propTypes.object.isRequired,
};
