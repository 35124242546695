import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import Menu from 'components/Menu'
import useNavStore from 'store/nav'
import useSiteStore from 'store/site'
import I18n from 'lang'


export default function Header(props) {
  const { exit = () => {} } = props
  const location = useLocation()
  const navStore = useNavStore()
  const siteStore = useSiteStore()

  const navItems = navStore.items?.map(
    item => {
      if (item.children) {
        return {
          label: item.title,
          active: item.children.some(child => location.pathname.includes(child.url)),
          options: item.children.map(
            child => ({
              active: location.pathname.includes(child.url),
              href: child.url.indexOf('http') === 0 ? child.url : ['/', child.locale, child.url].join('/').replace(/\/+/g, '/'),
              label: child.title,
              onClick: exit,
            })
          )
        }
      }

      return {
        active: location.pathname.includes(item.url),
        href: item.url.indexOf('http') === 0 ? item.url : ['/', item.locale, item.url].join('/').replace(/\/+/g, '/'),
        label: item.title,
        onClick: exit,
      }
    }
  )

  let path = location.pathname
  let isHome = false
  let spl = path.split('/')

  if (spl.length <= 2) {
    isHome = true
  }

  return (
    <>
      <div className='text-center'>
        <Link
          className='inline-block text-center hover:opacity-80 transition-all py-5'
          to='/'
        >
          <img
            className={classNames(
              'object-contain',
              {
                'max-h-[150px] max-w-[300px]': isHome,
                'max-h-[70px] max-w-[200px]': ! isHome,
              }
            )}
            src={siteStore.site?.header?.logo?.src} alt={siteStore.site?.header?.logo?.alt}
          />
        </Link>

        </div>
      <I18n t='welcome' />
      <nav className='border-t border-disabled-lighter'>
        <Menu
          parentClassName='w-full justify-center flex'
          className='menu-ppal uppercase font-semibold overflow-x-auto'
          items={navItems}
        />
      </nav>
    </>
  )
}
