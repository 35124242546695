import create from 'zustand'


const useTimeStore = create((set, get) => ({
  time: 0,
  timeInterval: null,
  reset: (time = 0) => {
    get().clear()
    set({ time })
  },
  start: () => {
    get().clear()
    const timeInterval = setInterval(() => {
      set({ time: get().time + 1 })
    }, 1000)
    set({
      timeInterval,
    })
  },
  clear: () => {
    if (get().timeInterval) {
      clearInterval(get().timeInterval)
    }
  },
}))

export default useTimeStore
